import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h3>
    <p>{`To use PaymentsDS with the Python SDK you need to have:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.python.org"
        }}>{`Python 3.5+`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://pip.pypa.io"
        }}>{`PIP`}</a></li>
    </ul>
    <h3 {...{
      "id": "receive-money-from-a-mobile-account-to-a-business-account"
    }}>{`Receive money from a mobile account to a business account`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`from paymentsds.mpesa import Client

client = Client(
   api_key='<REPLACE>',              # API Key
   public_key='<REPLACE>',           # Public Key
   service_provider_code='<REPLACE>' # input_ServiceProviderCode
)

try:
   payment_data = {
      'from': '841234567',       # input_CustomerMSISDN
      'reference': '11114',      # input_ThirdPartyReference
      'transaction': 'T12344CC', # input_TransactionReference
      'amount': '10'             # input_Amount
   }

   result = client.receive(payment_data)
except:
   print('Operation failed')
`}</code></pre>
    <h3 {...{
      "id": "send-money-from-a-business-account-to-a-mobile-account"
    }}>{`Send money from a business account to a mobile account`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`from paymentsds.mpesa import Client

client = Client(
   api_key='<REPLACE>',              # API Key
   public_key='<REPLACE>',           # Public Key
   service_provider_code='<REPLACE>' # input_ServiceProviderCode
)

try:
   payment_data = {
      'to': '841234567',         # input_CustomerMSISDN
      'reference': '11114',      # input_ThirdPartyReference
      'transaction': 'T12344CC', # input_TransactionReference
      'amount': '10'             # input_Amount
   }

   result = client.send(payment_data)
except:
   print('Operation failed')
`}</code></pre>
    <h3 {...{
      "id": "send-money-from-a-business-account-to-a-another-business-account"
    }}>{`Send money from a business account to a another business account`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`from paymentsds.mpesa import Client

client = Client(
   api_key='<REPLACE>',              # API Key
   public_key='<REPLACE>',           # Public Key
   service_provider_code='<REPLACE>' # input_ServiceProviderCode
)

try:
   payment_data = {
      'to': '979797',            # input_ReceiverPartyCode
      'reference': '11114',      # input_ThirdPartyReference
      'transaction': 'T12344CC', # input_TransactionReference
      'amount': '10'             # input_Amount
   }

   result = client.send(payment_data)
except:
   print('Operation failed')
`}</code></pre>
    <h3 {...{
      "id": "revert-a-transaction"
    }}>{`Revert a transaction`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`from paymentsds.mpesa import Client

client = Client(
   api_key='<REPLACE>',                # API Key
   public_key='<REPLACE>',             # Public Key
   service_provider_code='<REPLACE>',  # input_ServiceProviderCode
   initiatorIdentifier='<REPLACE>',    # input_InitiatorIdentifier,
   securityIdentifier='<REPLACE>'      # input_SecurityCredential
)

try:
   reversion_data = {
      'reference': '11114',      # input_ThirdPartyReference
      'transaction': 'T12344CC', # input_TransactionReference
      'amount': '10'             # input_ReversalAmount
   }

   result = client.revert(reversion_data)
except:
   # Handle success scenario
`}</code></pre>
    <h3 {...{
      "id": "query-the-status-of-a-transaction"
    }}>{`Query the status of a transaction`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      